import React, { Component, createContext, ReactNode } from "react";
import socket from "../socket";

interface Buzz {
  speed: number;
}

interface Buzzes {
  [userId: string]: Buzz;
}

interface ContextProps {
  status: string;
  buzzes: Buzzes;
  lockouts: string[];
  armed: boolean;
  locallyArmedAt?: number;
}

interface ProviderState {
  status: string;
  buzzes: Buzzes;
  lockouts: string[];
  locallyArmedAt?: number;
}

interface ProviderProps {
  children: ReactNode;
}

export const BuzzerContext = createContext({} as ContextProps);

interface BuzzerPayload {
  status: string;
  buzzes: Buzzes;
  lockouts: string[];
  arm?: boolean;
}
class BuzzerContextProvider extends Component<ProviderProps, ProviderState> {
  constructor(props: ProviderProps) {
    super(props);
    this.state = {
      status: "disarmed",
      buzzes: {},
      lockouts: [],
    };
  }

  componentDidMount() {
    socket.on("buzzer", (buzzer: BuzzerPayload) => {
      const { status, buzzes, lockouts, arm } = buzzer;

      if (arm) {
        const locallyArmedAt = Date.now();
        this.setState({ status, buzzes, lockouts, locallyArmedAt });
      } else {
        this.setState({ status, buzzes, lockouts });
      }
    });
  }

  armed() {
    return this.state.status === "armed";
  }

  render() {
    return (
      <BuzzerContext.Provider
        value={{
          status: this.state.status,
          buzzes: this.state.buzzes,
          lockouts: this.state.lockouts,
          armed: this.armed(),
          locallyArmedAt: this.state.locallyArmedAt,
        }}
      >
        {this.props.children}
      </BuzzerContext.Provider>
    );
  }
}

export default BuzzerContextProvider;
