import React, { useContext, useState } from "react";
import "./App.css";
import Lobby from "./lobby/Lobby";
import Host from "./host/Host";
import Player from "./player/Player";
import { Connection } from "./ConnectionProvider";
import PlayersContextProvider from "./providers/PlayersContextProvider";
import BuzzerContextProvider from "./providers/BuzzerContextProvider";

const App = () => {
  const { roomCode } = useContext(Connection);
  const [host, setHost] = useState(false);
  const toggleHost = () => setHost(!host);

  if (!roomCode) {
    return <Lobby host={host} toggleHost={toggleHost} />;
  } else {
    return (
      <PlayersContextProvider>
        <BuzzerContextProvider>
          {host ? <Host /> : <Player />}
        </BuzzerContextProvider>
      </PlayersContextProvider>
    );
  }
};

export default App;
