import React, { useState, useContext } from "react";
import PlayerBuzzer from "./PlayerBuzzer";
import { PlayersContext } from "../providers/PlayersContextProvider";
import { Connection } from "../ConnectionProvider";
import { Menu, Input, Grid } from "semantic-ui-react";
import VolumeControl from "../common/VolumeControl";
import ThemeControl from "../common/ThemeControl";
import socket from "../socket";
import { BuzzerContext } from "../providers/BuzzerContextProvider";
import useBuzzerSound from "../hooks/usePlayerBuzzerSound";

const Player = () => {
  const { players } = useContext(PlayersContext);
  const { userId, roomCode } = useContext(Connection);
  const { buzzes } = useContext(BuzzerContext);
  const [message, setMessage] = useState("");

  useBuzzerSound();

  const updateMessage = (newMessage: string) => {
    setMessage(newMessage);
    socket.emit("message host", { text: newMessage });
  };

  const player = players.find((player) => player.userId === userId);
  const buzz = buzzes[userId];
  const alreadyBuzzed = !!buzz?.speed;

  if (!player) {
    return null;
  }

  const name = player.name;
  const color = player.color;

  return (
    <>
      <Menu inverted size="massive">
        <Menu.Item header>{roomCode}</Menu.Item>
        <Menu.Item header active color={color}>
          {name}
        </Menu.Item>
        <Menu.Menu position="right">
          <ThemeControl />
          <VolumeControl />
        </Menu.Menu>
      </Menu>
      <Grid padded>
        <Grid.Row centered>
          <PlayerBuzzer playerId={userId} />
        </Grid.Row>
        <Grid.Row centered>
          <Input
            size="massive"
            maxLength={40}
            disabled={alreadyBuzzed}
            value={message}
            placeholder="Answer"
            onChange={(e) => updateMessage(e.target.value)}
          />
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Player;
