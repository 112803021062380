import React, { Component } from "react";
import { BuzzerContext } from "../providers/BuzzerContextProvider";
import socket from "../socket";
import { Button } from "semantic-ui-react";

class HostBuzzer extends Component {
  static contextType = BuzzerContext;

  componentDidMount = () => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        this.armBuzzer();
      } else if (e.key === "Escape") {
        this.disarmBuzzer();
      }
    });
  };

  armBuzzer = () => {
    socket.emit("arm buzzer");
  };

  disarmBuzzer = () => {
    socket.emit("disarm buzzer");
  };

  render = () => {
    const { armed } = this.context as any;

    if (!armed) {
      return (
        <Button.Group size="massive">
          <Button onClick={this.armBuzzer} size="massive" color="red">
            Activate
          </Button>
        </Button.Group>
      );
    } else {
      return (
        <Button.Group size="massive">
          <Button onClick={this.disarmBuzzer} color="grey">
            Deactivate
          </Button>
          <Button onClick={this.armBuzzer} color="red">
            Reactivate
          </Button>
        </Button.Group>
      );
    }
  };
}

export default HostBuzzer;
