import { useEffect, useContext } from "react";
import useSound from "use-sound";
import beepSfx from "../sounds/beep.mp3";
import { BuzzerContext } from "../providers/BuzzerContextProvider";
import { SoundContext } from "../providers/SoundContextProvider";

const useBuzzerSound = () => {
  const { locallyArmedAt, buzzes } = useContext(BuzzerContext);
  const { volume } = useContext(SoundContext);

  const [beep] = useSound(beepSfx, { volume });

  useEffect(() => {
    const numberOfBuzzes = Object.keys(buzzes).length;
    if (numberOfBuzzes > 0) {
      const playbackRate = 2 - numberOfBuzzes * 0.1;
      beep({ playbackRate });
    }
  }, [buzzes, beep]);

  useEffect(() => {
    if (locallyArmedAt) {
      beep({ playbackRate: 1 });
    }
  }, [locallyArmedAt, beep]);
};

export default useBuzzerSound;
