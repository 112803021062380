import React from "react";
import EditPlayerPopup from "./EditPlayerPopup";
import KickPlayerPopup from "./KickPlayerPopup";
import PlayerLock from "./PlayerLock";
import { Popup, Table, Label, Button, Header } from "semantic-ui-react";
import { Player } from "../providers/PlayersContextProvider";
import socket from "../socket";

export interface PlayerData extends Player {
  speed?: number;
  locked: boolean;
}

const updateScore = (player: Player, score: number) => {
  socket.emit("update player score", { userId: player.userId, score });
};

const PlayerRow = (props: { player: PlayerData }) => {
  const { player } = props;
  const { name, color, speed, message, score } = player;

  return (
    <Table.Row key={name}>
      <Table.Cell>
        <PlayerLock player={player} />
        <Label color={color}>{name}</Label>
      </Table.Cell>
      <Table.Cell>
        <Header as="h5">{speed && `${(speed / 1000).toFixed(4)}s`}</Header>
      </Table.Cell>
      <Table.Cell>
        <Button.Group size="mini">
          <Button
            size="mini"
            icon="minus"
            color="red"
            onClick={() => updateScore(player, player.score - 1)}
          />
          <Button.Or text={score} />
          <Button
            size="mini"
            icon="plus"
            color="green"
            onClick={() => updateScore(player, player.score + 1)}
          />
        </Button.Group>
      </Table.Cell>
      <Table.Cell>
        <p>{message}</p>
      </Table.Cell>
      <Table.Cell>
        <Button.Group>
          <Popup
            content={<EditPlayerPopup player={player} />}
            on="click"
            trigger={<Button icon="pencil" />}
          />
          <Popup
            content={<KickPlayerPopup player={player} />}
            on="click"
            trigger={<Button icon="dont" color="red" />}
          />
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );
};

export default PlayerRow;
