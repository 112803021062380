import React, { Component } from "react";
import { BuzzerContext } from "../providers/BuzzerContextProvider";
import socket from "../socket";
import { Button } from "semantic-ui-react";

interface Props {
  playerId: string;
}
class PlayerBuzzer extends Component<Props> {
  static contextType = BuzzerContext;

  componentDidMount() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        this.buzz();
      }
    });
  }

  buzz = () => {
    const { armed, locallyArmedAt } = this.context as any;

    if (armed && !this.alreadyBuzzed()) {
      const speed = Date.now() - locallyArmedAt;

      socket.emit("buzz", { speed });
    }
  };

  buzzSpeed = () => {
    const { buzzes } = this.context as any;
    const buzz = buzzes[this.props.playerId];

    return buzz ? (buzz.speed / 1000).toFixed(4) : null;
  };

  alreadyBuzzed = () => !!this.buzzSpeed();

  render = () => {
    const { armed, lockouts } = this.context as any;
    const locked = lockouts.includes(this.props.playerId);

    let button = (
      <Button
        color="yellow"
        style={{ fontSize: "5vh", width: "90%", height: "100%" }}
      >
        {locked ? "Locked out!" : "Wait..."}
      </Button>
    );

    if (armed && !locked) {
      button = (
        <Button
          onClick={this.buzz}
          color="green"
          style={{ fontSize: "5vh", width: "90%", height: "100%" }}
        >
          BUZZ!
        </Button>
      );
    }

    if (this.buzzSpeed() && !locked) {
      button = (
        <Button
          color="red"
          style={{ fontSize: "5vh", width: "90%", height: "100%" }}
        >
          {this.buzzSpeed()}s
        </Button>
      );
    }

    return button;
  };
}

export default PlayerBuzzer;
