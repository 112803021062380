import React, { useContext } from "react";
import useSound from "use-sound";
import beepSfx from "../sounds/beep.mp3";
import { Menu } from "semantic-ui-react";
import { SoundContext } from "../providers/SoundContextProvider";

const VolumeControl = () => {
  const { volume, setVolume } = useContext(SoundContext);

  const [beep] = useSound(beepSfx, { volume });

  const changeVolume = (volume: number) => {
    setVolume(volume);
    volume > 0 && beep();
  };

  switch (volume) {
    case 1:
      return (
        <Menu.Item
          position="right"
          icon="volume up"
          onClick={() => {
            changeVolume(0);
          }}
        />
      );
    case 0:
      return (
        <Menu.Item
          position="right"
          icon="volume off"
          onClick={() => {
            changeVolume(0.5);
          }}
        />
      );
    default:
      return (
        <Menu.Item
          position="right"
          icon="volume down"
          onClick={() => {
            changeVolume(1);
          }}
        />
      );
  }
};

export default VolumeControl;
