import React, { useState } from "react";
import { Button, Divider, Input, Label } from "semantic-ui-react";
import { PlayerColor } from "../providers/PlayersContextProvider";
import socket from "../socket";
import { PlayerData } from "./PlayerRow";

interface Props {
  player: PlayerData;
}

const EditPlayerPopup = (props: Props) => {
  const { player } = props;
  const [name, setName] = useState(player.name);
  const [color, setColor] = useState<PlayerColor>(player.color);

  const colorOptions = [
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "teal",
    "blue",
    "violet",
    "purple",
    "pink",
    "brown",
    "grey",
    undefined,
  ] as PlayerColor[];

  const updatePlayer = (name: string, color: PlayerColor) => {
    socket.emit("edit player", { userId: player.userId, color, name });
  };

  return (
    <>
      <Input value={name} onChange={(e) => setName(e.target.value)} />
      <Divider />
      {colorOptions.map((colorOption) => (
        <Label
          as="button"
          color={colorOption}
          size={colorOption === color ? "medium" : "mini"}
          onClick={() => {
            setColor(colorOption);
          }}
        >
          {colorOption || "white"}
        </Label>
      ))}
      <Divider />
      <Button
        onClick={() => {
          updatePlayer(name, color);
        }}
      >
        Save
      </Button>
    </>
  );
};

export default EditPlayerPopup;
