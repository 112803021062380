import React from "react";
import { Button, Divider, Header } from "semantic-ui-react";
import socket from "../socket";
import { PlayerData } from "./PlayerRow";

interface Props {
  player: PlayerData;
}

const EditPlayerPopup = (props: Props) => {
  const { player } = props;

  const kickPlayer = () => {
    socket.emit("kick player", { userId: player.userId });
  };

  return (
    <>
      <Header as="h4">Are you sure you want to kick {player.name}?</Header>
      <Divider />
      <Button onClick={kickPlayer}>Kick</Button>
    </>
  );
};

export default EditPlayerPopup;
