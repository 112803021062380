import { useEffect, useContext } from "react";
import useSound from "use-sound";
import beepSfx from "../sounds/beep.mp3";
import { BuzzerContext } from "../providers/BuzzerContextProvider";
import { SoundContext } from "../providers/SoundContextProvider";

const useBuzzerSound = () => {
  const { locallyArmedAt } = useContext(BuzzerContext);
  const { volume } = useContext(SoundContext);

  const [beep] = useSound(beepSfx, { volume });

  useEffect(() => {
    if (locallyArmedAt) {
      beep({ playbackRate: 1 });
    } else {
      beep({ playbackRate: 2 });
    }
  }, [locallyArmedAt, beep]);
};

export default useBuzzerSound;
