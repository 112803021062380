import React from "react";
import Header from "../common/Header";
import HostForm from "./HostForm";
import PlayerForm from "./PlayerForm";
import { Grid, Button } from "semantic-ui-react";

interface Props {
  host: boolean;
  toggleHost: () => void;
}

const Lobby = (props: Props) => {
  const { host, toggleHost } = props;

  return (
    <>
      <Header text="BZZR" />
      <Grid padded>
        <Grid.Row centered>{host ? <HostForm /> : <PlayerForm />}</Grid.Row>
        <Grid.Row centered>
          <Button onClick={toggleHost}>
            {host ? "I want to play!" : "I want to host!"}
          </Button>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Lobby;
