import React, { Component, createContext, ReactNode } from "react";

interface ContextProps {
  volume: number;
  setVolume: (volume: number) => void;
}

interface ProviderState {
  volume: number;
}

interface ProviderProps {
  children: ReactNode;
}

export const SoundContext = createContext({} as ContextProps);

class SoundContextProvider extends Component<ProviderProps, ProviderState> {
  constructor(props: ProviderProps) {
    super(props);
    this.state = {
      volume: 1,
    };
  }

  componentDidMount() {
    const rememberedVolume = window.localStorage.getItem("volume");
    if (rememberedVolume) {
      const volume = parseFloat(rememberedVolume);
      isNaN(volume) ? this.setVolume(1) : this.setVolume(volume);
    }
  }

  componentDidUpdate() {
    window.localStorage.setItem("volume", `${this.state.volume}`);
  }

  setVolume(volume: number) {
    this.setState({ volume });
  }

  render() {
    return (
      <SoundContext.Provider
        value={{
          volume: this.state.volume,
          setVolume: this.setVolume.bind(this),
        }}
      >
        {this.props.children}
      </SoundContext.Provider>
    );
  }
}

export default SoundContextProvider;
