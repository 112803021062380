import React, { useContext, useEffect, useReducer } from "react";
import sortBy from "lodash/sortBy";
import { PlayersContext } from "../providers/PlayersContextProvider";
import { BuzzerContext } from "../providers/BuzzerContextProvider";
import useBuzzerSound from "../hooks/useHostBuzzerSound";
import PlayerRow, { PlayerData } from "./PlayerRow";
import { Header, Table } from "semantic-ui-react";
import { MessengerContext } from "./Messenger";

const PlayerTable = () => {
  const { players } = useContext(PlayersContext);
  const { buzzes, lockouts } = useContext(BuzzerContext);
  const { messages } = useContext(MessengerContext);

  const tableReducer = (state: any, action: any) => {
    switch (action.type) {
      case "UPDATE_PLAYERS":
        return {
          ...state,
          data: sortBy(action.data, [state.column]),
        };
      case "CHANGE_SORT":
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === "ascending" ? "descending" : "ascending",
          };
        }

        return {
          column: action.column,
          data: sortBy(state.data, [action.column]),
          direction: "ascending",
        };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(tableReducer, {
    column: "speed",
    data: [],
    direction: "ascending",
  });

  useEffect(() => {
    const playersData: PlayerData[] = players.map((player) => ({
      ...player,
      locked: lockouts.includes(player.userId),
      speed: buzzes[player.userId] ? buzzes[player.userId].speed : undefined,
      message: messages[player.userId],
    }));

    dispatch({ type: "UPDATE_PLAYERS", data: playersData });
  }, [players, buzzes, lockouts, messages, dispatch]);

  const { column, data, direction } = state;

  useBuzzerSound();

  if (Object.keys(data).length === 0) {
    return (
      <Header color="grey" as="h3">
        Waiting for players...
      </Header>
    );
  }

  return (
    <Table unstackable collapsing compact sortable fixed size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={column === "name" ? direction : null}
            onClick={() => dispatch({ type: "CHANGE_SORT", column: "name" })}
          >
            Player
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "speed" ? direction : null}
            onClick={() => dispatch({ type: "CHANGE_SORT", column: "speed" })}
          >
            Buzz Speed
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "score" ? direction : null}
            onClick={() => dispatch({ type: "CHANGE_SORT", column: "score" })}
          >
            Score
          </Table.HeaderCell>
          <Table.HeaderCell>Message</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((player: PlayerData) => (
          <PlayerRow player={player} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default PlayerTable;
