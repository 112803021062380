import React, { Component, createContext, ReactNode } from "react";

type Theme = "light" | "dark";

interface ContextProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

interface ProviderState {
  theme: Theme;
}

interface ProviderProps {
  children: ReactNode;
}

export const ThemeContext = createContext({} as ContextProps);

class ThemeContextProvider extends Component<ProviderProps, ProviderState> {
  constructor(props: ProviderProps) {
    super(props);
    this.state = {
      theme: "light",
    };
  }

  componentDidMount() {
    const theme = window.localStorage.getItem("theme") as Theme;
    if (theme) {
      this.setTheme(theme);
    }
  }

  componentDidUpdate() {
    window.localStorage.setItem("theme", `${this.state.theme}`);
  }

  setTheme(theme: Theme) {
    this.setState({ theme });
  }

  render() {
    return (
      <ThemeContext.Provider
        value={{
          theme: this.state.theme,
          setTheme: this.setTheme.bind(this),
        }}
      >
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

export default ThemeContextProvider;
