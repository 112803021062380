import React, { Component, createContext, ReactNode } from "react";
import socket from "../socket";

export type PlayerColor =
  | "red"
  | "orange"
  | "yellow"
  | "olive"
  | "green"
  | "teal"
  | "blue"
  | "violet"
  | "purple"
  | "pink"
  | "brown"
  | "grey"
  | undefined;

export interface Player {
  userId: string;
  name: string;
  color?: PlayerColor;
  message?: string;
  score: number;
}

interface ContextProps {
  players: Player[];
}

interface ProviderState {
  players: Player[];
}

interface ProviderProps {
  children: ReactNode;
}

export const PlayersContext = createContext({} as ContextProps);

class PlayersContextProvider extends Component<ProviderProps, ProviderState> {
  constructor(props: ProviderProps) {
    super(props);
    this.state = {
      players: [],
    };
  }

  componentDidMount() {
    socket.on("players", (payload: { players: Player[] }) => {
      const { players } = payload;
      this.setState({ players });
    });
  }

  render() {
    return (
      <PlayersContext.Provider
        value={{
          players: this.state.players,
        }}
      >
        {this.props.children}
      </PlayersContext.Provider>
    );
  }
}

export default PlayersContextProvider;
