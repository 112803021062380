import React, { Component, createContext, ReactNode } from "react";
import socket from "./socket";

interface ContextProps {
  roomCode: string;
  userId: string;
}

interface ProviderState {
  roomCode: string;
  userId: string;
}

interface ProviderProps {
  children: ReactNode;
}

export const Connection = createContext({} as ContextProps);

class ConnectionProvider extends Component<ProviderProps, ProviderState> {
  constructor(props: ProviderProps) {
    super(props);
    this.state = {
      roomCode: "",
      userId: "",
    };
  }

  componentDidMount() {
    socket.on(
      "join room",
      (connection: { roomCode: string; userId: string }) => {
        this.setState(connection);
      }
    );

    socket.on("kick", () => {
      this.setState({ roomCode: "" });
      socket.emit("leave room");
    });

    socket.on("error", (error: { message: string }) => {
      const { message } = error;
      alert(message);
    });
  }

  render() {
    return (
      <Connection.Provider
        value={{
          roomCode: this.state.roomCode,
          userId: this.state.userId,
        }}
      >
        {this.props.children}
      </Connection.Provider>
    );
  }
}

export default ConnectionProvider;
