import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import App from "./App";
import ConnectionProvider from "./ConnectionProvider";
import SoundContextProvider from "./providers/SoundContextProvider";
import ThemeContextProvider from "./providers/ThemeContextProvider";

ReactDOM.render(
  <React.StrictMode>
    <ConnectionProvider>
      <SoundContextProvider>
        <ThemeContextProvider>
          <App />
        </ThemeContextProvider>
      </SoundContextProvider>
    </ConnectionProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
