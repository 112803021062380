import React from "react";
import { PlayerData } from "./PlayerRow";
import { Button } from "semantic-ui-react";
import socket from "../socket";

interface Props {
  player: PlayerData;
}

const PlayerLock = (props: Props) => {
  const {
    player: { userId, locked },
  } = props;

  const unlock = () => {
    socket.emit("unlock players", { userIds: [userId] });
  };

  const lock = () => {
    socket.emit("lock players", { userIds: [userId] });
  };

  const toggleLock = () => {
    locked ? unlock() : lock();
  };

  return (
    <Button
      icon={locked ? "lock" : "lock open"}
      color={locked ? "yellow" : undefined}
      onClick={toggleLock}
    />
  );
};

export default PlayerLock;
