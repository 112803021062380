import React, { Component, createContext, ReactNode } from "react";
import socket from "../socket";

type Messages = { [userId: string]: string };

interface ContextProps {
  messages: Messages;
}

interface ProviderState {
  messages: Messages;
}

interface ProviderProps {
  children: ReactNode;
}

export const MessengerContext = createContext({} as ContextProps);

class Messenger extends Component<ProviderProps, ProviderState> {
  constructor(props: ProviderProps) {
    super(props);
    this.state = {
      messages: {},
    };
  }

  componentDidMount() {
    socket.on("message", (payload: { playerId: string; text: string }) => {
      const { playerId, text } = payload;

      this.setState({
        messages: { ...this.state.messages, [playerId]: text },
      });
    });
  }

  render() {
    return (
      <MessengerContext.Provider
        value={{
          messages: this.state.messages,
        }}
      >
        {this.props.children}
      </MessengerContext.Provider>
    );
  }
}

export default Messenger;
