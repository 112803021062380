import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

const userIdKey = "bzzr-userId";
let userId = window.localStorage.getItem(userIdKey);
if (!userId) {
  userId = uuidv4();
  window.localStorage.setItem(userIdKey, userId);
}

let socket = io({
  query: {
    userId,
  },
});

socket.on("disconnect", (reason: string) => {
  if (reason === "io server disconnect") {
    alert(
      "You have been disconnected from the server. Taking you back to the lobby."
    );
    window.location.reload();
  }
});

export default socket;
