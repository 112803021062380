import React, { useState } from "react";
import socket from "../socket";
import { Grid, Input, Button } from "semantic-ui-react";

const HostForm = () => {
  const [roomCode, setRoomCode] = useState("");

  const joinRoom = (roomCode: string) => {
    socket.emit("host existing", { roomCode });
  };

  const createRoom = () => {
    socket.emit("host new");
  };

  return (
    <Grid padded>
      <Grid.Row centered>
        <Button size="massive" onClick={() => createRoom()}>
          Host a New Game
        </Button>
      </Grid.Row>
      <Grid.Row centered>
        <Input
          placeholder="Room Code"
          size="large"
          maxLength={4}
          value={roomCode}
          action
          onChange={(e) =>
            setRoomCode(
              [...e.target.value.matchAll(/[a-zA-z]/g)].join("").toUpperCase()
            )
          }
        >
          <input />
          <Button
            size="large"
            disabled={roomCode.length !== 4}
            onClick={() => joinRoom(roomCode)}
          >
            Resume Hosting
          </Button>
        </Input>
      </Grid.Row>
    </Grid>
  );
};

export default HostForm;
