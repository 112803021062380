import React, { useState } from "react";
import { Grid, Input, Button } from "semantic-ui-react";
import socket from "../socket";

const PlayerForm = () => {
  const [roomCode, setRoomCode] = useState("");
  const [name, setName] = useState(
    window.localStorage.getItem("bzzr-username") || ""
  );

  const joinRoom = (roomCode: string) => {
    window.localStorage.setItem("bzzr-username", name.trim());
    socket.emit("play", { roomCode, name: name.trim() });
  };

  return (
    <Grid padded>
      <Grid.Row centered>
        <Input
          placeholder="Room Code"
          size="massive"
          maxLength={4}
          value={roomCode}
          onChange={(e) =>
            setRoomCode(
              [...e.target.value.matchAll(/[a-zA-z]/g)].join("").toUpperCase()
            )
          }
        />
      </Grid.Row>{" "}
      <Grid.Row centered>
        <Input
          placeholder={`Name`}
          size="massive"
          maxLength={12}
          value={name}
          onChange={(e) =>
            setName([...e.target.value.matchAll(/[a-zA-z0-9 ]/g)].join(""))
          }
        />
      </Grid.Row>
      <Grid.Row centered>
        <Button
          size="massive"
          disabled={roomCode.length !== 4 || name.length === 0}
          onClick={() => joinRoom(roomCode)}
        >
          Join the Game
        </Button>
      </Grid.Row>
    </Grid>
  );
};

export default PlayerForm;
