import React from "react";
import { Menu, StrictGridColumnProps } from "semantic-ui-react";
import VolumeControl from "./VolumeControl";
import ThemeControl from "./ThemeControl";

interface Props {
  text: string;
  color?: StrictGridColumnProps["color"];
}

const AppHeader = (props: Props) => {
  const { text, color } = props;

  return (
    <Menu inverted size="massive">
      <Menu.Item header active={!!color} color={color}>
        {text}
      </Menu.Item>
      <Menu.Menu position="right">
        <ThemeControl />
        <VolumeControl />
      </Menu.Menu>
    </Menu>
  );
};

export default AppHeader;
