import React, { useContext } from "react";
import { Menu } from "semantic-ui-react";
import { ThemeContext } from "../providers/ThemeContextProvider";

const ThemeControl = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const DarkStyle = () => (
    <style
      dangerouslySetInnerHTML={{
        __html: `
body { background-color: #222 };
`,
      }}
    />
  );

  switch (theme) {
    case "dark":
      return (
        <>
          <Menu.Item
            icon="lightbulb outline"
            onClick={() => {
              setTheme("light");
            }}
          />
          <DarkStyle />
        </>
      );
    default:
      return (
        <>
          <Menu.Item
            icon="lightbulb"
            onClick={() => {
              setTheme("dark");
            }}
          />
        </>
      );
  }
};

export default ThemeControl;
