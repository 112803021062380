import React, { useContext } from "react";
import Header from "../common/Header";
import HostBuzzer from "./HostBuzzer";
import { Connection } from "../ConnectionProvider";
import PlayerList from "./PlayerTable";
import { Grid } from "semantic-ui-react";
import Messenger from "./Messenger";

const Host = () => {
  const { roomCode } = useContext(Connection);

  return (
    <>
      <Header text={roomCode} />
      <Grid padded>
        <Grid.Row centered>
          <Messenger>
            <PlayerList />
          </Messenger>
        </Grid.Row>
        <Grid.Row centered>
          <HostBuzzer />
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Host;
